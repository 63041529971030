// order_item_controller.js
import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {
    static values = {
        mode: String,
        mode: String,
        mode: String,
        mode: String,
        mode: String,
        // 'pkg': mode,
        // 'validPkg': mode,
        // 'qty': mode,
        // 'price': mode,
        // 'priceType': mode,
    }

    connect() {
        console.log('order-item controller connected');
    }

    sku(e) {
        console.log('sku',e);
        // ajax to fetch the product info - title, valid pkg, price for qty, price type
        // need to pass: sku, qty, cust id
        // spinner on price and total
        this.setPrice();
        this.calculateTotal();
    }

    pkg(e) {
        console.log('pkg',e);
        // uppercase it
        // check that it's a valid pkg
        // fetch price, pass sku, pkg, qty, custid
        this.setPrice();
        this.calculateTotal();
    }

    qty(e) {
        console.log('qty',e);
        // check that it's a valid qty int > 0
        // fetch price, pass sku, pkg, qty, custid
        this.setPrice();
        this.calculateTotal();
    }

    price(e) {
        console.log('price',e);
    }

    save(e) {
        console.log('save',e);
        // save the new line item, and end editing
        // refresh the line in the new mode
    }

    cancel(e) {
        console.log('cancel',e);
        // end editing without saving the changes to the line item
        // refresh the line in the new mode
    }

    getPrice() {  // sku, pkg, qty custId
    // spinner on line total
    // use outlet or event to tell shipment and order controllers to invalidate the impacted values
    // return both price and priceType
    }

    setPrice() {
        //update the price and price type
        // remove the price spinner
    }
    calculateTotal() {
        //update the total
        // remove the total spinner
    }
}
