import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {
    static targets = [
        'createLabelView',
        'actualWeight',
        'actualCost',
        'trackingCode',
        'spinner',
        'voidButton',
        'reprintButton',
    ]
    static values = {
        url: String,
        shipmentId: Number,
        numShipments: Number,
        createEpShipmentUrl: String,
        buyEpShipmentUrl: String,
        printShippingLabelUrl: String,
        markAsPackedUrl: String,
        voidShippingLabelUrl: String,
    }

    connect() {
        super.connect();
        this.createShippingLabel();
    }

    createShippingLabel() {
        this.createLabelViewTarget.classList.add('active');
        this.spinnerTarget.style.display = 'block';
        this.createEPShipment()
            .then((response) => {
                let shipmentInfo = JSON.parse(response.data);
                if (shipmentInfo.error) {
                    console.log(shipmentInfo.error);
                    this.trackingCodeTarget.innerHTML = shipmentInfo.error;
                    return null;
                }
                this.actualCostTarget.innerHTML = shipmentInfo.cost;
                this.buyEPShipment()
                    .then((response) => {
                        let trackingInfo = JSON.parse(response.data);
                        this.trackingCodeTarget.innerHTML = trackingInfo.tracking;
                        // Print the label
                        this.printLabel()
                            .then((response) => {
                                this.markAsPacked();

                                this.spinnerTarget.style.display = 'none';
                                this.createLabelViewTarget.classList.remove('active');
                                this.createLabelViewTarget.classList.add('completed');
                                this.voidButtonTarget.classList.remove('disabled');
                                this.reprintButtonTarget.classList.remove('disabled');
                            })
                            .catch(function (error) {
                                console.log(error);
                                return null;
                            });
                    })
                    .catch(function (error) {
                        console.log(error);
                        return null;
                    });
                return true;
            })
            .catch(function (error) {
                console.log(error);
                return null;
            });
    }

    createEPShipment() {
        return new Promise((resolve, reject) => {
            axios.post(this.createEpShipmentUrlValue, {
                shipmentId: this.shipmentIdValue,
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    //console.log(error);
                    reject(error);
                });
        });
    }

    buyEPShipment() {
        return new Promise((resolve, reject) => {
            axios.post(this.buyEpShipmentUrlValue, {
                shipmentId: this.shipmentIdValue,
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    //console.log(error);
                    reject(error);
                });
        });
    }

    printLabel() {
        return new Promise((resolve, reject) => {
            axios.post(this.printShippingLabelUrlValue, {
                shipmentId: this.shipmentIdValue,
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    //console.log(error);
                    reject(error);
                });
        });
    }

    voidLabel() {
        return new Promise((resolve, reject) => {
            axios.post(this.voidShippingLabelUrlValue, {
                shipmentId: this.shipmentIdValue,
            })
                .then(function (response) {
                    let refundStatus = JSON.parse(response.data);

                    resolve(refundStatus);
                })
                .catch(function (error) {
                    //console.log(error);
                    reject(error);
                });
        });
    }

    markAsPacked() {
        return new Promise((resolve, reject) => {
            axios.post(this.markAsPackedUrlValue, {
                shipmentId: this.shipmentIdValue,
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    voidButton() {
        this.voidButtonTarget.classList.add('disabled');
        this.voidLabel().then((response) => {
            this.trackingCodeTarget.innerHTML = response.refund_status;
            this.voidButtonTarget.classList.remove('disabled');
        });
    }

    reprintButton() {
        this.reprintButtonTarget.classList.add('disabled');
        this.printLabel().then(() => {
            this.reprintButtonTarget.classList.remove('disabled');
        });
    }
}
