// timeago_controller.js
import { Controller } from '@hotwired/stimulus';
import Timeago from 'stimulus-timeago'

export default class extends Timeago {

    connect() {
        super.connect();
    }

}
