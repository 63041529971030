// checkbox_select_all_controller.js
import { Controller } from '@hotwired/stimulus';
import axios from 'axios';
import CheckboxSelectAll from 'stimulus-checkbox-select-all'

export default class extends CheckboxSelectAll {
    static targets = ["count"];
    static values = {
        url: String,
    }

    connect() {
        super.connect();
        console.log('connect');
    }


    action() {
        // Submitting a form is a better way to get a new page based on a POST request
        // Use a hidden field for the checkedIds
        let checkedIds = this.checked.map(a => a.value);
        document.getElementById('ids').value = JSON.stringify(checkedIds);
        document.getElementById('theForm').submit();

        // get checked ids from checked
        // axios.post(this.urlValue, {
        //         ids: this.checked,
        //     })
        //     .then(function (response) {
        //         console.log(response);
        //         document.open();
        //         document.write(response.data);
        //         document.close();
        //     })
        //     .catch(function(error) {
        //         console.log(error);
        //     });
    }
}
