import { Controller } from '@hotwired/stimulus';
import axios from 'axios';
//import Scale from '../../public/js/scale.js';

// import PrintNode from '../printnode-ws-http-client';

export default class extends Controller {
    static targets = [
        'scaleView',
        'scalePlaceItemView',
        'scaleRemoveItemView',
        'packingView',
        'weighingView',
        'createLabelView',
        'shipmentView',
        'itemsView',
        'itemQty',
        'itemSKU',
        'packingQty',
        'packingSKU',
        'itemRow',
        'itemCount',
        'autoBuyPrint',
        'actualWeight',
        'actualCost',
        'trackingCode',
        'currentShipmentIndex',
        'prevButton',
        'nextButton',
    ]
    static values = {
        url: String,
        shipmentIndex: Number,
        shipmentList: Array,
        shipmentId: Number,
        numShipments: Number,
        ajaxShipmentPostUrl: String,
        shipmentViewUrl: String,
        itemsViewUrl: String,
        fulfillmentUrl: String,
        shippingLabelViewUrl: String,
        mtTestEnvFakeScale: String,
        packedTransitionUrlValue: String,    }

    connect() {
        super.connect();
        this.packingState = 'prepacking';
        this.nextButtonTarget.disabled = (this.numShipmentsValue <= 1);
        this.prePackingStep(this.shipmentIdValue);

    }

    keyPress(e) {
        //console.log('keyPress event:', e);
        switch (e.key) {
            case 'ArrowLeft':
                e.preventDefault();
                this.prevShipment(e);
                break;
            case 'ArrowRight':
                e.preventDefault();
                this.nextShipment(e);
                break;
            case ' ':
                e.preventDefault();
                if (document.getElementById('shipment-status').innerText == 'Ready for Packing') {
                    this.packingStateMachine();
                }
                break;
            default:
                break;
        }
    }

    nextStep(e) {
        this.packingStateMachine();
    }

    packingStateMachine() {
        switch(this.packingState) {
            case 'prepacking':
                if (this.prePackingStep(this.shipmentIdValue)) {
                }
                break;
            case 'packing':
                if (this.packingStep(this.shipmentIdValue)) {
                }
                break;
        }
    }

    prePackingStep(shipmentId) {
        // prepare to start packing
        window.scrollTo(0,0);
        this.packingViewTarget.classList.add('active');
        this.itemIndex = -1;
        this.packingState = 'packing';
        this.packingStep(shipmentId);
        return true;
    }
    packingStep(shipmentId) {
        let numItems = this.itemSKUTargets.length;
        this.itemIndex++;

        this.highlightItemRow(this.itemIndex);

        if (this.itemIndex >=0 && this.itemIndex < numItems) {
            this.itemCountTarget.innerHTML = this.itemIndex+1 + ' of ' + numItems + ' line items';
            this.packingQtyTarget.innerHTML = 'Qty&nbsp;&nbsp;&nbsp;' + this.itemQtyTargets[this.itemIndex].innerHTML;
            this.packingSKUTarget.innerHTML = this.itemSKUTargets[this.itemIndex].innerHTML;
            return false; // Still items to pack. Stay in packing state.
        }

        if (this.itemIndex === numItems) {
            this.itemCountTarget.innerHTML = '';
            this.packingQtyTarget.innerHTML = '';
            this.packingSKUTarget.innerHTML = '';
        }
        this.weighingStep(shipmentId);
        return true;
    }

    weighingStep(shipmentId) {
        this.packingViewTarget.classList.remove('active');
        this.packingViewTarget.classList.add('completed');
        this.weighingViewTarget.classList.add('active');

        if (this.mtTestEnvFakeScaleValue == 'true') {
            console.log('Faking scale weight');
            let weightOz = 3;
            this.setWeightAndPrintLabel(shipmentId, weightOz);
        } else {
            document.querySelector('#scale').scale.startWeighing(this, (context, formattedWeight, weightOz) => {
                this.setWeightAndPrintLabel(shipmentId, weightOz);
          });
        }

        return true;
    }

    setWeightAndPrintLabel(shipmentId, weightOz)
    {
        this.setShipmentWeight(shipmentId, weightOz)
            .then((response) => {
                this.weighingViewTarget.classList.remove('active');
                this.weighingViewTarget.classList.add('completed');
                this.createShipmentLabel(shipmentId);
                this.nextShipmentStep();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    nextShipmentStep() {
        if (this.shipmentIndexValue < this.numShipmentsValue) {
            this.nextShipment();
         }
        return true;
    }

    highlightItemRow(itemIndex) {
        //console.log('highlightItemRow');
        // Mark previous row, if there is on as packed
        if (itemIndex > 0 && itemIndex <= this.itemSKUTargets.length) {
            if (this.itemQtyTargets[itemIndex-1].innerText > 1)
                alert('Confirm item qty of ' + this.itemQtyTargets[itemIndex-1].innerText + '.');
            this.itemRowTargets[itemIndex-1].classList.remove('active');
            this.itemRowTargets[itemIndex-1].classList.add('packed');
        }
        // Mark the current row as active
        if (itemIndex < this.itemSKUTargets.length) {
            this.itemRowTargets[itemIndex].classList.add('active');
            this.itemRowTargets[itemIndex].scrollIntoView(true);

        }
    }

    createShipmentLabel(shipmentId) {

        axios.post(this.shippingLabelViewUrlValue, {
            shipmentId: shipmentId,
        })
            .then( (response) => {
                document.getElementById('new-shipping-label').outerHTML = response.data;
            })
            .catch(function(error) {
                console.log(error);
            });


    }

    // TTD The next 4 functions are all the same except for the URl, combine into a single function
    setShipmentWeight(shipmentId,weightOz) {
        return new Promise( (resolve, reject) => {
            axios.post(this.ajaxShipmentPostUrlValue, {
                id:shipmentId,
                boxWeight: weightOz,
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    //console.log(error);
                    reject(error);
                });
        });

    }


    resetPackingProgress() {
        console.log('resetPackingProgress');
        this.itemCountTarget.innerHTML = '';
        this.packingQtyTarget.innerHTML = '';
        this.packingSKUTarget.innerHTML = '';
        this.itemIndex = -1;
        this.packingState = 'packing';
        this.packingViewTarget.classList.remove('active');
        this.packingViewTarget.classList.remove('completed');
        this.weighingViewTarget.classList.remove('active');
        this.weighingViewTarget.classList.remove('completed');
    }

    resetItemsProgress() {
        console.log('resetting items progress');
        this.itemRowTargets.forEach((itemRow) => {
            itemRow.classList.remove('active');
            itemRow.classList.remove('packed');
        });

    }

    prevShipment() {
        if (this.shipmentIndexValue > 0) {
            this.shipmentIndexValue--
            this.currentShipmentIndexTarget.innerHTML = this.shipmentIndexValue+1;
            this.shipmentIdValue = this.shipmentListValue[this.shipmentIndexValue];
            this.loadShipment(this.shipmentIdValue);
            if (document.getElementById('shipment-status').innerText == 'Ready for Packing') {
                this.prePackingStep()
            }
        }
        this.prevButtonTarget.disabled = (this.shipmentIndexValue <= 0) ;
        this.nextButtonTarget.disabled = (this.shipmentIndexValue > this.numShipmentsValue+1) ;
    }

    nextShipment() {
        if (this.shipmentIndexValue < this.numShipmentsValue-1) {
            this.shipmentIndexValue++
            this.currentShipmentIndexTarget.innerHTML = this.shipmentIndexValue+1;
            this.shipmentIdValue = this.shipmentListValue[this.shipmentIndexValue];
            this.loadShipment(this.shipmentIdValue);
        } else {
            // past the last shipment
            this.loadShipment(-1);

        }
        this.prevButtonTarget.disabled = (this.shipmentIndexValue <= 0);
        this.nextButtonTarget.disabled = (this.shipmentIndexValue >= this.numShipmentsValue-1) ;
    }

    loadShipment(shipmentId) {
        // shipmentId of -1 loads the "Last Shipment Has Been Packed" page
        //console.log('loading shipment ' + this.shipmentIdValue)
        document.getElementById('shipment-spinner').style.display = 'flex';
        document.getElementById('shipment-spinner2').style.display = 'flex';
        this.resetPackingProgress();
        this.itemsViewTarget.style.display = 'none';
        axios.post(this.shipmentViewUrlValue, {
                shipmentId: shipmentId,
            })
            .then( (response) => {
                console.log('shipment view reloaded');
                this.shipmentViewTarget.innerHTML = response.data;
            })
            .catch(function(error) {
                console.log(error);
            });

        if (shipmentId > 0) {
            axios.post(this.itemsViewUrlValue, {
                shipmentId: shipmentId,
            })
                .then((response) => {
                    console.log('items view reloaded');
                    this.itemsViewTarget.outerHTML = response.data;
                    document.getElementById('shipment-spinner').style.display = 'none';
                    document.getElementById('shipment-spinner2').style.display = 'none';
                    if (document.getElementById('shipment-status').innerText == 'Ready for Packing') {
                        this.prePackingStep()
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }

}
