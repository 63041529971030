import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';
import axios from "axios";
export default class extends Controller {
    static targets = ['modal', 'modalBody'];
    static values = {
        formUrl: String,
    }
    connect() {
        console.log('modal-form -controller connected.');
    }
    openModal(event) {
        this.modalBodyTarget.innerHTML = 'Loading...';
        const modal = new Modal(this.modalTarget);
        modal.show();
        axios.get(this.formUrlValue)
            .then((response) => {
                this.modalBodyTarget.innerHTML = response.data
            });

    }
}
