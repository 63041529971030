import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {

    connect() {
    }

    pickupCompleted({params: {carrier,url}}) {
        console.log('pickup completed', carrier, url);
        return new Promise( (resolve, reject) => {
            axios.post(url, {
                carrier: carrier,
            })
                .then(function (response) {
                    resolve(response);
                    document.getElementById('carrier-pickup-card').outerHTML = response.data;
                })
                .catch(function (error) {
//                    console.log(error);
                    reject(error);
                });
        });
    }
}
