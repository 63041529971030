import { Controller } from '@hotwired/stimulus';
import Autocomplete from 'stimulus-autocomplete'

export default class extends Controller {
    connect() {
        this.element.textContent = 'Hello Stimulus! Edit me in assets/controllers/hello_controller.js';
        const application = Application.start()
        application.register('autocomplete', Autocomplete)
    }
}
