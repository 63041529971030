// shipment_late_controller.js
import { Controller } from '@hotwired/stimulus';
import axios from "axios";

export default class extends Controller {
    static values = {
        orderviewurl: String,
        shipmentlateurl: String,
    };

    connect() {
    }

    cancel(event) {
        window.location.href = this.orderviewurlValue
    }

    continue(event) {
        axios.post(this.shipmentlateurlValue, {})
            .then((response) => {
            })
            .catch(function (error) {
                console.log(error);
            });
        window.location.href = this.orderviewurlValue
    }

}
