import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {
    static values = {
   }

    connect() {
        super.connect();
        //console.log('connect');
    }

    packingslipPrint(e) {
        this.print(e.params.url)
    }

    packingslipPDF(e) {
        console.log(e);
        this.downloadPDF(e.params.url, 'packingSlips.pdf', e.target);
    }

    decRefSheetsPrint(e) {
        this.print(e.params.url)
    }

    decRefSheetsPDF(e) {
        this.downloadPDF(e.params.url, 'decRefSheets.pdf', e.target);
    }

    customRulersPrint(e) {
        this.print(e.params.url)
    }

    customRulersPDF(e) {
        this.downloadPDF(e.params.url, 'customRulers.pdf', e.target);
    }

    rulerSetInstructionsPrint(e) {
        this.print(e.params.url)
    }

    rulerSetInstructionsPDF(e) {
        this.downloadPDF(e.params.url, 'rulerSetInst.pdf', e.target);
    }

    printAll(e) {
        // e.params.urls.forEach((url) => {console.log(url)});
        e.params.urls.forEach((url) => {this.print(url)});
    }

    downloadPDF(url,filename,target) {
        target.disabled = true;
        axios.get(url,{responseType: 'arraybuffer'})
            .then((response) => {
                console.log(response);
                var blob=new Blob([response.data],{type: 'application/pdf'});
                var link=document.createElement('a');
                link.href=window.URL.createObjectURL(blob);
                link.download=filename;
                link.click();
                target.disabled = false;

            });

    }

    print(url) {
        console.log('Print:', url);
        axios.get(url, {responseType: 'arraybuffer'})
            .then((response) => {
                console.log(response);
            });
    }
}
