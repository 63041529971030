// src/controllers/clipboard_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 'source', 'copied' ]

    copy(event) {
        console.log('copied: ',event,this.sourceTarget)
        navigator.clipboard.writeText(this.sourceTarget.textContent);
        this.copiedTarget.removeAttribute('hidden');
    }
}