import { Controller } from '@hotwired/stimulus';
const PrintNode = require('../printnode-ws-http-client');

export default class extends Controller {
    
    static targets = [
        'scaleValue',
        'scaleStatus',
    ];

    static values = {
        computerid: String,
    }

    connect() {
        super.connect();
        this.status = 'offline';
        this.lastWeight = 0;
        this.weightOz = -1;
        this.lastScaleMsgTS = Date.now();
        this.scaleWeightSameTS = Date.now();
        this.scaleValue = '-----';
        this.weighingState = 'unknown';
            // this.scaleState = 'unknown';
        // this.scaleZeroed = false;
        this.element[this.identifier] = this;
        this.printNode(this);
        setInterval( () => this.scaleDisplayUpdate(), 500); // Time in milliseconds
    }

    startWeighing(completedContext, completed) {
        this.completed = completed
        this.completedContext = completedContext;
        this.weighingState = 'start';
    }

    // Call this every second
    // Scale States: offline, zeroed, not-zeroed, unstable, valid-weight
    scaleDisplayUpdate() {
        //----------------------------
        // Scale Status Logic
        let lastStatus = this.status;
        let ts = Date.now();
        // If we have not heard form the scale in 10 seconds, it's offline
        if (this.weightOz === -1) {
            this.status = 'not-zeroed';
            this.scaleValue = '-----';
        } else if (this.weightOz === 0) {
            this.status = 'zeroed';
            this.scaleValue = '0 oz';
            this.scaleWeightSameTS = ts;
        } else if (ts - this.lastScaleMsgTS > 10000) {
            this.status = 'offline';
            this.scaleValue = '-----';
        } else if (ts - this.scaleWeightSameTS >= 1000) {
            // reported mass has been the same for at least 2 seconds, mass is considered stable
            this.status = 'valid-weight';
        } else {
            this.status = 'unstable';
            this.scaleValue = '-----';
        }
        //if (lastStatus !== this.status) console.log(this.status, this.weightOz);
        //----------------------------
        // Weighing Logic
        //console.log(this.status, this.weighingState);
        switch (this.weighingState) {
            case 'start':
                if (this.status !== 'zeroed') {
                    this.scaleValue = 'Clear Scale';
                    //this.weighingState = 'Clear Scale';
                } else {
                    this.weighingState = 'zeroed';
                }
                break;
            case 'zeroed':
                if (this.status === 'zeroed') {
                    this.scaleValue = 'Place Parcel on Scale';
                    //this.weighingState = 'Place Parcel on Scale';
                } else {
                    document.getElementById('weighing-spinner').style.display = 'block';
                    this.weighingState = 'placed';
                }
                break;
            case 'placed':
                if (this.status === 'valid-weight') {
                    document.getElementById('weighing-spinner').style.display = 'none';
                    this.scaleValue = this.formattedWeight(this.weightOz);
                    this.weighingState = 'completed';
                    this.completed(this.completedContext, this.formattedWeight(this.weightOz),this.weightOz);
                }
                break;
       }
        //----------------------------
        // Display Update Logic
        this.scaleValueTarget.innerHTML = this.scaleValue; // this.formattedWeight(this.weightOz);
        this.scaleStatusTarget.innerHTML = this.status;
        //console.log(this.weighingState,this.status);
    }

    formattedWeight(weight) {
        if (weight >= 16) {
            let lbs = Math.ceil(weight / 16);
            return lbs + ' lb';
        } else {
            return weight + ' oz';
        }
    }

    // Called every time the scale websocket sends a new measurement
    //      Weight changes are sent immediately
    //      Stable values are sent every 7 seconds
    //      No messages arrive if the scale is offline
    //
    scaleMeasurement(measurement) {
        //console.log(measurement.clientReportedCreateTimestamp, measurement.mass[0]);
        //console.log(measurement.computerId,' ' ,this.computeridValue);
        if(measurement.computerId == this.computeridValue) { // MT pi id 307588, MT John's Mac id 36557, Skylonda computer id 507579 TTD pass the scale id  with the HTML page and put it here
            // check for invalid data
            if (measurement.mass[0] === null) {
                this.weightOz = -1;
            } else {
                // We have a valid mass, convert it from micrograms, to ounces
                this.weightOz = Math.ceil(Number(measurement.mass[0]) * 3.5273990723E-8);
            }
            let ts = Date.now();
            this.lastScaleMsgTS = ts;
            // Save the timestamp of a new and different mass value.
            // Same weight id defined as +/- half an ounce.
            //console.log(measurement.mass[0] - 14174750, this.lastWeight, measurement.mass[0] + 14174750)
            if (this.lastWeight > measurement.mass[0] + 14174750 || this.lastWeight < measurement.mass[0] - 14174750) {
                this.lastWeight = measurement.mass[0];
                this.scaleWeightSameTS = ts;
                //console.log('new weight', measurement.mass[0] - 14174750, this.lastWeight, measurement.mass[0] + 14174750, ts);
            } else {
                //console.log('same weight', measurement.mass[0] - 14174750, this.lastWeight, measurement.mass[0] + 14174750);
            }
        }
    }

    printNode(controller) {
        if (PrintNode.WebSocket.isSupported()) {
            // once a websocket is authenticated you can register
            function authenticated (authData) {
                if (authData.error) {
                    // most likely not authenticated, see authData.error for more detail
                    console.log('Authentication Error: ', authData.error)
                    return;
                }
                // authData will contain information about accountId, permissions, and maxSubscriptions

                // ok, now make some requests to the server to get data you're interested in
                // pass in optional second argument to have this called when server publishes a event
                // but you can also use the event emitter
                this.getScales({}, function (measurement) {
                    // this is only meaningful if the websocket is running on the same machine
                    // as the running PrintNode client
//                    console.log("scales data by subscription callback", measurement);
//                    console.log("scales latency %dms", measurement.getLatency());
                });

            }

            // error callback fired if anything goes wrong including
            //  - exceptions thrown by subscription callbacks
            //  - network issues which cause socket to fail or any timeouts
            //  - errors in printnode server or client libs
            function errorCallback (err, data) {
                console.log("Error!", err, data);
            }

            // instantiate a new
            var ws = new PrintNode.WebSocket(
                {apiKey: 'a6476d278161c00660ee5dc7994ec9811823b018'},
                authenticated,
                errorCallback
            );

            // // subscribe to all computer events
            // ws.subscribe("computer", function computerEvent (something, info) {
            //     // lots of things could come in on this event
            //     // for now we're only interested in scalesMeasurements
            //     if (something instanceof PrintNode.ScalesMeasurement) {
            //         console.log("scales data by computer subscription", something);
            //     }
            // });

            // subscribe to all scales events
            ws.subscribe("scales", function computerEvent (measurement) {
                controller.scaleMeasurement(measurement);
            });

            // debugging
            ws.subscribe('system.state', function stateChange (newState) {
                //console.log("newState", newState);
            });

            // TODO fallback to polling
        } else {
            var httpOptions = {
                success: function (responseBody, response) {
                    console.log("success", response);
                },
                error: function (responseBody, response) {
                    console.log(response);
                },
                complete: function (response) {
                    console.log(
                        "%d %s %s returned %db in %dms",
                        response.xhr.status,
                        response.reqMethod,
                        response.reqUrl,
                        response.xhr.responseText.length,
                        response.getDuration()
                    );
                },
                timeout: function (url, duration) {
                    console.log("%s timeout %d", url, duration);
                }
            };

            var http = new PrintNode.HTTP(
                new PrintNode.HTTP.ApiKey(API_KEY),
                httpOptions
            );

            http.scales(
                {success: function (response) {
                        console.log("success from callback", response);
                    }},
                {computerId: 0}
            ).then(
                function (response, info ) {
                    console.log("success from promise", response);
                },
                function (err) {
                    throw "simulated exception in a promise callback";
                }
                // If your promise callbacks start throw[ing] you can 'catch' this in the returned
                // promise from .then() but this is getting pretty meta right here.
                // Callbacks really shouldn't throw exceptions in async code like this.
            ).then(null, console.log.bind(console, "promise callbacks threw error;"));

        }
    }
}
