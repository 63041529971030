import { Controller } from '@hotwired/stimulus';
import axios from "axios";

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller='hello' attribute will cause
 * this controller to be executed. The name 'hello' comes from the filename:
 * hello_controller.js -> 'hello'
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    static values = {
        cancelStatus: String,
        shippedurl: String,
        orderviewurl: String,
        cancelurl: String,
    };

    canCancel = false;

    connect() {
        // this.cancelStatusValue is one of: not-packed, waiting-for-pickup, already-shipped, already-canceled
        document.getElementById(this.cancelStatusValue).removeAttribute('hidden');
        if (this.cancelStatusValue == 'not-packed') {
            document.getElementById('cancel-instructions').removeAttribute('hidden');
            document.getElementById('cancel-button').removeAttribute('hidden');
            this.canCancel = true;
        }
        if (this.cancelStatusValue == 'waiting-for-pickup') {
            document.getElementById('waiting-for-pickup').removeAttribute('hidden');
            //document.getElementById('cancel-button').removeAttribute('hidden');
            this.canCancel = false; // might switch to true is shipment has not been picked up
        }
        if (this.cancelStatusValue == 'already-canceled') {
            document.getElementById('already-canceled').removeAttribute('hidden');
            this.canCancel = false;
        }
        if (this.cancelStatusValue == 'already-shipped') {
            document.getElementById('already-shipped').removeAttribute('hidden');
            document.getElementById('cancel-button').innerHTML = 'Continue';
            document.getElementById('cancel-button').removeAttribute('hidden');
            this.canCancel = false;
        }
        // document.getElementById('already-canceled').setAttribute('hidden', '');
    }

    retrieved(event) {
        document.getElementById('cancel-continue-btn').removeAttribute('hidden');
        this.canCancel = true;
    }

    pickedup(event) {
        document.getElementById('cancel-continue-btn').removeAttribute('hidden');
        this.canCancel = false;
    }

    cancelContinue(event) {
        document.getElementById('waiting-for-pickup').setAttribute('hidden', '');
        document.getElementById('cancel-continue-btn').setAttribute('hidden', '');
        if (document.getElementById('retrieved').checked) {
            document.getElementById('cancel-instructions').removeAttribute('hidden');
            document.getElementById('cancel-button').removeAttribute('hidden');
        }
        if (document.getElementById('picked-up').checked) {
            document.getElementById('already-shipped').removeAttribute('hidden');
            // POST to do the carrier pickup action for the shipment
            axios.get(this.shippedurlValue, {
            })
                .then( (response) => {
                })
                .catch(function(error) {
                    console.log(error);
                });
            // provide some UI progress indicator
            // on successful post, switch to the already-shipped view
        }

    }

    amazonCanceled(event) {
        console.log('amazonCanceled')
    }

    custMsgSent(event) {
        console.log('custMsgSent')
    }

    abort(event) {
        window.location.href = this.orderviewurlValue
    }

    cancel(event) {

        if (this.canCancel) {

            axios.post(this.cancelurlValue, {})
                .then((response) => {
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        window.location.href = this.orderviewurlValue
    }

}
