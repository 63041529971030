// order_action_controller.js
import { Controller } from '@hotwired/stimulus';

export default class {

    connect() {
        // console.log('order-action-controller connected.');
    }

}
