import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {
    connect() {
        console.log('order_transitions_controller connected.')
    }
    static values = {
        transitionUrl: String
    }
    transition(event) {
        //event.preventDefault();
        console.log('ajax call to: '+this.transitionUrlValue);
        axios.get(this.transitionUrlValue)
            .then((response) => {
                console.log(response);
            });
    }
}
