import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {
    static values = {
        url: String,
    }

    connect() {
        super.connect();
        console.log('connect');
    }
    clickAction() {
        console.log('click-action');
        // console.log(this.numShipmentsValue);
        // fetch('/test/foo', {
        //     method: 'POST',
        //     body: 'FooBody',
        // })
        //     .then(function (response) {
        //         //console.log(response);
        //         document.open();
        //         document.write(response.data);
        //         document.close();
        //     })
        axios.post(this.urlValue, JSON.stringify({key: 'Foo Data', bar: 'More Data', num: 12})
            // JSON.stringify({
            // action: actionType,
            // shipmentIndex: this.shipmentIndexValue,
            // })
        )
        .then(function (response) {
            //console.log(response);
            document.open();
            document.write(response.data);
            document.close();
        })
        .catch(function(error) {
            console.log(error);
        });
    }
}